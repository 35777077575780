@use 'colors' as c;
@import '_cookieAlert';

*,
*::before,
*::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html {
	font-size: 62.5%;
	scroll-behavior: smooth;
}
//Zaznaczanie tekstu w kolorze różowym
::selection {
	background-color: #d63a94;
	color: #fff;
}

//suwak scroll start Chrome, Edge, Safari
::-webkit-scrollbar {
	width: 1.5rem;
}
::-webkit-scrollbar-thumb {
	background: linear-gradient(transparent, c.$pink);
	border-radius: 100vw;
	border: 0.25em solid #333;
}
::-webkit-scrollbar-thumb:hover {
	background: linear-gradient(transparent, c.$pinkHover);
}

::-webkit-scrollbar-track {
	background: #333;
}

//koniec

body {
	position: relative;
	font-family: 'Montserrat', sans-serif;
	font-family: 'Poppins', sans-serif;
	background-color: #fff;
}

.fadeIn {
	transform: translateY(20px);
	opacity: 0;
	transition: 1s ease;
}
.fadeIn--visible {
	transform: translateY(0);
	opacity: 1;
}

// NAWIGACJA
.navbar {
	min-height: 5em;
	.navbar-toggler {
		border: none;
		outline: none;
		background-color: rgba(33, 33, 33, 0.5); //
		padding: 0.5em; //
		border-radius: 10px; //
		.navbar-toggler-icon {
			.fa-bars {
				color: #fff;
				font-size: 2rem;
			}
		}
	}
	.offcanvas {
		background-color: #000;
		.offcanvas-header {
			.btn-close {
				.fa-xmark {
					color: #fff;
					font-size: 2rem;
				}
			}
		}
		.offcanvas-body {
			margin-top: 5em;
			.nav-item {
				.nav-link {
					color: white;
					font-size: 3rem;
					text-transform: uppercase;
					font-weight: 700;
					padding: 0 0.2em;
				}
				.nav-link:hover {
					color: c.$pinkHover;
				}
				.nav-link:focus {
					box-shadow: none;
					color: c.$pinkHover;
				}
				.dropdown-menu {
					border: none;
					box-shadow: 0 0 15px gray;
					.dropdown-item {
						font-size: 1.5rem;
						padding: 0.5em;
					}
					.dropdown-item:hover {
						color: #fff;
						background-color: c.$pinkHover;
					}
					.dropdown-item:focus {
						outline: none;
						color: #fff;
						background-color: c.$pinkHover;
					}
				}
			}
		}
	}
}
@media (min-width: 768px) {
	.navbar {
		.navbar-toggler {
			margin-top: 1em; //
			padding: 1em 1.2em 1.2em 1em; //
			border-radius: 13px; //
			.navbar-toggler-icon {
				.fa-bars {
					font-size: 2.5rem; //
				}
			}
		}
		.offcanvas {
			.offcanvas-header {
				.btn-close {
					.fa-xmark {
						font-size: 2.5rem; //
					}
				}
			}
			.offcanvas-body {
				margin-top: 7em; //
				.nav-item {
					.nav-link {
						font-size: 3.5rem; //
					}
					.dropdown-menu {
						.dropdown-item {
							font-size: 1.8rem; //
						}
					}
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.navbar {
		min-height: 5em;
		background-color: rgba(33, 33, 33, 0.7);
		color: #fff;
		.offcanvas {
			.offcanvas-body {
				margin-top: 0;
				.nav-item {
					.nav-link {
						text-transform: none;
						color: white;
						font-size: 1.7rem;
						font-weight: 300;
						padding: 0 1em;
					}
					.dropdown-menu {
						.dropdown-item {
							font-size: 1.5rem;
							padding: 0.5em;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	.navbar {
		min-height: 7em;
		.offcanvas {
			.offcanvas-body {
				.nav-item {
					.nav-link {
						font-size: 2rem;
					}
					.dropdown-menu {
						.dropdown-item {
							font-size: 1.8rem;
						}
					}
				}
			}
		}
	}
}

// =======HEADER-sekcja wstępna=======
header {
	position: relative;
	.home-background {
		min-height: 100vh;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		position: relative;
		overflow: hidden;
		.home-video {
			position: absolute;
			top: 0;
			right: 0;
			z-index: -2;
			object-fit: cover;
			width: 100%;
			height: 100%;
			pointer-events: none;
			user-select: none;
			-webkit-user-select: none;
			touch-action: none;
		}
		.home-text {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			height: 100vh;
			padding: 0 1em;
			font-family: 'Poppins', sans-serif;
			text-align: center;
			color: #fff;
			text-shadow: 0 0 10px #000;
			.nonVisible {
				display: none;
			}
			img {
				width: 30rem; // respon
				opacity: 0.6;
				filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8));
				position: absolute;
				top: 40%;
				left: 50%;
				transform: translate(-50%, -40%);
				z-index: -1;
			}
			.headTxt {
				font-size: 3.2rem; // respon
				font-weight: 700;
				padding-bottom: 10px;
				letter-spacing: -1px;

				span {
					color: c.$pink;
				}
			}
			.headerText {
				font-size: 1.5rem; // respon
				font-weight: 100;
				margin-top: -5px;
			}
		}

		.headerBtn {
			padding: 0.5em 1em;
			margin-top: 1em;
			font-size: 1rem;
			font-weight: 500;
			text-transform: uppercase;
			text-decoration: none;
			color: #fff;
			border-radius: 10px;
			border: none;
			background-color: c.$pink;
			transition: background-color 0.3s;
		}

		.headerBtn:hover {
			background-color: c.$btnHover;
		}
		.headerBtn:focus {
			outline: none;
			background-color: c.$btnHover;
		}
	}
}

// =========================
//			MEDIA
// =========================
@media (min-width: 768px) {
	header {
		.home-background {
			.home-text {
				img {
					width: 70rem;
				}
				.headTxt {
					font-size: 6rem;
				}
				.headerText {
					font-size: 2.5rem;
				}
			}
			.headerBtn {
				font-size: 1.5rem;
			}
		}
	}
}
@media (min-width: 992px) {
	header {
		.home-background {
			.home-text {
				img {
					width: 60vw;
				}
				.headTxt {
					font-size: 7rem;
				}
				.headerText {
					font-size: 2.5rem;
				}
			}
		}
	}
}
@media (min-width: 1020px) {
	header {
		.home-background {
			.home-text {
				img {
					width: 90rem;
				}
				.headTxt {
					font-size: 7rem;
				}
				.headerText {
					font-size: 3rem;
				}
			}
			.headerBtn {
				font-size: 1.5rem;
			}
		}
	}
}
@media (min-width: 1020px) and (orientation: landscape) {
	header {
		.home-background {
			.home-text {
				img {
					width: 80rem;
				}
				.headTxt {
					font-size: 5.5rem;
				}
				.headerText {
					font-size: 2.2rem;
				}
			}
			.headerBtn {
				font-size: 1.3rem;
			}
		}
	}
}
@media (min-width: 1800px) and (orientation: landscape) {
	header {
		.home-background {
			.home-video {
				position: fixed;
			}
			.home-text {
				img {
					width: 110rem;
				}
				.headTxt {
					font-size: 6rem;
				}
				.headerText {
					font-size: 3rem;
				}
			}
			.headerBtn {
				font-size: 1.5rem;
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	header {
		.home-background {
			.home-text {
				img {
					width: 150rem;
				}
				.headTxt {
					font-size: 10rem;
				}
				.headerText {
					font-size: 4rem;
				}
			}
			.headerBtn {
				font-size: 2rem;
			}
		}
	}
}

// =======SEKCJA O NAS=======
.aboutus {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: relative;
	.flexSection {
		display: grid;
		grid-template-rows: 1fr;
		min-height: 100vh;
		width: 100%;
		position: relative;
		.bgcLeft {
			height: 100%;
			background-color: #fff;
			box-shadow: 0px 0px 50px black;
			z-index: 5;
			.abtLeft {
				display: flex;
				align-items: center;
				height: 100%;
				.headerAboutUs {
					padding: 7em 1em;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					.headerAbtUs {
						display: flex;
						align-items: center;
						margin-bottom: 3em;
						padding-left: 1em;
						.stripeAboutUs {
							width: 2.5em;
							height: 0.2em;
							background-color: c.$pinkHover;
							border-radius: 5px;
							rotate: 90deg;
						}
						h2 {
							font-family: 'Poppins', sans-serif;
							font-size: 2rem;
							font-weight: 300;
							text-transform: uppercase;
							margin: 0;
							span {
								font-size: 2rem;
								font-weight: 700;
							}
						}
					}

					p {
						text-align: center;
						font-family: 'Montserrat', sans-serif;
						font-size: 1.5rem;
						font-weight: 400;
						text-indent: 2rem;
						padding: 0 1em;
						margin: 0;
						strong {
							font-weight: 400;
						}
					}
					.bottomAbt {
						margin-top: 2em;
					}
				}
			}
		}
		.abtRight {
			min-height: 60vh;
			width: 100%;
			position: relative;
			overflow: hidden; // Upewniamy się, że wszystko poza kontenerem jest ukryte
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			transition: background-image 1.5s ease-in-out;

			&.bg1 {
				background-image: url('../img/firstOwner.jpg');
			}
			&.bg2 {
				background-image: url('../img/sittingTogether.jpg');
			}
			&.bg3 {
				background-image: url('../img/secondOwner.jpg');
			}
			&.bg4 {
				background-image: url('../img/efektOffice.jpg');
			}
			&.bg5 {
				background-image: url('../img/takingLeaflet.jpg');
			}

			.abtRightContent {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0.2);
				z-index: 2; // Przykład z-index dla treści
			}
		}
	}
	.bottomStripe {
		width: 100%;
		min-height: 5rem;
		background-color: #000;
		overflow: hidden;
		white-space: nowrap;
		z-index: 6;
		position: absolute;
		top: 100%;
		left: 0%;
		transform: translate(-0%, -100%);
		.stripeContent {
			display: inline-block;
			animation: 35s patrons infinite linear;
			img {
				height: 5rem;
				margin: 0 15px;
				aspect-ratio: 3/2;
				object-fit: contain;
			}
		}
	}
}

// =========================
//		   ANIMATIONS
// =========================

@keyframes bgFade {
	0%,
	12% {
		opacity: 1;
	}
	13%,
	20% {
		opacity: 0;
	}
	21%,
	32% {
		opacity: 1;
	}
	33%,
	40% {
		opacity: 0;
	}
	41%,
	52% {
		opacity: 1;
	}
	53%,
	60% {
		opacity: 0;
	}
	61%,
	72% {
		opacity: 1;
	}
	73%,
	80% {
		opacity: 0;
	}
	81%,
	92% {
		opacity: 1;
	}
	93%,
	100% {
		opacity: 0;
	}
}
@keyframes patrons {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}
// =========================
//			MEDIA
// =========================

@media (min-width: 768px) {
	.aboutus {
		.flexSection {
			.bgcLeft {
				.abtLeft {
					.headerAboutUs {
						padding: 10em 3em;
						.headerAbtUs {
							margin-bottom: 4em;
							.stripeAboutUs {
								width: 5em;
								height: 0.3em;
							}
							h2 {
								font-size: 3rem;
								span {
									font-size: 3rem;
								}
							}
						}
						p {
							font-size: 1.7rem;
						}
					}
				}
			}
		}
		.bottomStripe {
			min-height: 7rem;
			.stripeContent {
				img {
					height: 7rem;
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.aboutus {
		.flexSection {
			.bgcLeft {
				.abtLeft {
					.headerAboutUs {
						padding: 10em 3em;
						.headerAbtUs {
							margin-bottom: 4em;
							.stripeAboutUs {
								width: 5em;
								height: 0.3em;
							}
							h2 {
								font-size: 3.5rem;
								span {
									font-size: 3.5rem;
								}
							}
						}
						p {
							font-size: 1.8rem;
						}
					}
				}
			}
		}
		.bottomStripe {
			min-height: 7rem;
			.stripeContent {
				img {
					height: 7rem;
				}
			}
		}
	}
}
@media (min-width: 1020px) and (orientation: landscape) {
	.aboutus {
		.flexSection {
			grid-template-columns: 1fr 1fr;
			.bgcLeft {
				.abtLeft {
					.headerAboutUs {
						padding: 7em 2em 7em 2em;
						.headerAbtUs {
							margin-bottom: 3em;
							padding-left: 1em;
							.stripeAboutUs {
								width: 3em;
								height: 0.2em;
							}
							h2 {
								font-size: 2.2rem;
								span {
									font-size: 2.2rem;
								}
							}
						}

						p {
							font-size: 1.4rem;
							padding: 0 2em;
						}
						.bottomAbt {
							margin-top: 2em;
							margin-bottom: 2em;
						}
					}
				}
			}
			.abtRight {
				height: 100%;
			}
		}
		.bottomStripe {
			min-height: 5rem;
			.stripeContent {
				img {
					height: 5rem;
				}
			}
		}
	}
}
@media (min-width: 1800px) and (orientation: landscape) {
	.aboutus {
		.flexSection {
			.bgcLeft {
				.abtLeft {
					.headerAboutUs {
						.headerAbtUs {
							.stripeAboutUs {
								width: 4em;
								height: 0.3em;
							}
							h2 {
								font-size: 3rem;
								span {
									font-size: 3rem;
								}
							}
						}

						p {
							font-size: 1.7rem;
							padding: 0 2em;
						}
					}
				}
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	.aboutus {
		.flexSection {
			.bgcLeft {
				.abtLeft {
					.headerAboutUs {
						.headerAbtUs {
							padding-bottom: 3em;
							.stripeAboutUs {
								width: 6em;
								height: 0.3em;
							}
							h2 {
								font-size: 4rem;
								span {
									font-size: 4rem;
								}
							}
						}

						p {
							font-size: 2.2rem;
						}
					}
				}
			}
		}
		.bottomStripe {
			min-height: 7rem;
			.stripeContent {
				img {
					height: 7rem;
				}
			}
		}
	}
}

// =======OFERTA=======
.ourOffert {
	width: 100%;
	min-height: 100vh;
	.sloganOffert {
		min-height: 30vh; //
		width: 100%;
		padding: 7em 1em;
		background-color: c.$pink;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		z-index: 2;
		h3 {
			width: 100%; //
			margin: 0;
			font-family: 'Poppins', sans-serif;
			font-size: 2rem; //
			font-weight: 700;
			color: white;
			text-transform: uppercase;
			text-align: center;
			text-shadow: 0 0 30px #000;
		}
		.socialText {
			font-family: 'Poppins', sans-serif;
			color: #fff;
			font-size: 2rem; //
			font-weight: 200;
			text-align: center;
			margin: 0.5em 2em 0.5em 2em; //
		}
		.socialBox {
			display: flex;
			justify-content: center;
			align-items: center;
			.socialLink {
				font-size: 1.5rem;
				background-color: rgba(177, 39, 119, 0.7);
				border-radius: 5px;
				padding: 0.5em 1em;
				margin: 0;
				a:link,
				a:visited {
					color: #fff;
					text-decoration: none;
				}
				a:hover {
					color: #000;
					.socialText {
						color: #000;
					}
				}
				a:focus {
					outline: none;
					color: #000;
					.socialText {
						color: #000;
					}
				}
				.socialText {
					font-size: 1.5rem;
					margin: 0 0 0 0.5em;
					font-weight: 400;
				}
			}
			.socialLink:first-of-type + .socialLink {
				margin-left: 2em;
			}
		}
	}
	.contentOffert {
		width: 100%;
		.whiteStripeOffert {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 2em 1em;
			position: relative;
			box-shadow: 0 0 20px #000;
			background-color: #fff;
			z-index: 3;
			.titleOff {
				width: 100%;
				display: flex;
				align-items: center;
				padding: 2em 0;
				h2 {
					font-family: 'Poppins', sans-serif;
					font-size: 2.5rem;
					font-weight: 300;
					margin: 0;
					.textOff {
						font-family: 'Poppins', sans-serif;
						font-size: 2.5rem;
						font-weight: 700;
					}
				}
				.stripeOff {
					width: 4em;
					height: 0.2em;
					background-color: c.$pinkHover;
					border-radius: 5px;
					rotate: 90deg;
				}
			}
			.contentOff {
				padding: 0 2em;
				p {
					font-family: 'Poppins', sans-serif;
					font-size: 1.5rem;
					font-weight: 400;
					text-indent: 2em;
					margin: 0;
				}
				p:first-of-type + p {
					margin: 2em 0;
				}
			}
		}
		.carouselSection {
			width: 100%;
			min-height: 50rem;
			background-color: #333;
			.flexSection {
				.logoElement {
					display: none;
				}
				.logos {
					width: 100%;
					min-height: 50rem;
					display: flex;
					align-items: center;
					overflow: hidden;
					white-space: nowrap;
					.logos-slide {
						display: inline-block;
						animation: slide 80s linear infinite;
						.slides {
							display: inline-block;
							width: 30rem;
							height: 40rem;
							margin: 0 1em;
							box-shadow: 0 0 5px #fff;
							border-radius: 15px;
							background-size: cover;
							.graySection {
								width: 100%;
								height: 100%;
								display: flex;
								align-items: start;
								overflow: hidden;
								.content {
									width: 100%;
									height: 100%;
									display: flex;
									flex-direction: column;
									align-items: center;
									font-family: 'Poppins', sans-serif;
									border-radius: 15px;
									background-color: rgba(0, 0, 0, 0.3);
									overflow: hidden;

									h3 {
										padding: 1em 0 0.5em 0;
										margin: 0;
										color: white;
										font-weight: 700;
										font-size: 3rem;
										text-shadow: 0 0 10px #000;
									}
									p {
										width: 100%;
										text-wrap: wrap;
										color: white;
										padding: 0.5em 0.6em;
										margin: 0;
										font-weight: 300;
										font-size: 1.6rem;
										text-shadow: 0 0 10px #000;
										text-align: center;
									}
								}
							}
						}
						.slide1 {
							background-image: url(../img/slide1.jpg);
						}
						.slide2 {
							background-image: url(../img/slide2.jpg);
						}
						.slide3 {
							background-image: url(../img/slide3.jpg);
						}
						.slide4 {
							background-image: url(../img/slide4.jpg);
						}
						.slide5 {
							background-image: url(../img/slide5.jpg);
						}
						.slide6 {
							background-image: url(../img/slide6.jpg);
						}
						.slide7 {
							background-image: url(../img/slide7.jpg);
						}
						.slide8 {
							background-image: url(../img/slide8.jpg);
						}
						.slide9 {
							background-image: url(../img/slide9.jpg);
						}
						.slide10 {
							background-image: url(../img/slide10.jpg);
						}
						.slide11 {
							background-image: url(../img/slide11.jpg);
						}
						.slide12 {
							background-image: url(../img/slide12.jpg);
						}
					}
				}
			}
			.logos:hover .logos-slide {
				animation-play-state: paused;
			}
		}
	}

	.bottomContentOffert {
		background-color: #fff;
		box-shadow: 0 0 20px #000;
		.rightContent {
			padding: 3em 1em;
			font-family: 'Poppins', sans-serif;
			.topSectionTxt {
				display: flex; //
				flex-direction: column; //
				justify-content: center; //
				align-items: center;
				h3 {
					margin: 0;
					font-size: 1.5rem;
					font-weight: 500;
					text-transform: uppercase;
				}
				.streetName {
					font-size: 3.5rem;
					font-weight: 800;
				}
				.reviewLink {
					font-size: 1.2rem;
					text-transform: uppercase;
					background-color: c.$pink;
					border-radius: 5px;
					margin: 0;
					transition: background-color 0.3s;
					cursor: pointer;
					a {
						padding: 1em 2em;
					}
					a:link,
					a:visited {
						color: #fff;
						text-decoration: none;
					}
					a:focus {
						outline: none;
						color: #000;
					}
				}
				.reviewLink:hover {
					background-color: c.$btnHover;
				}
			}
			.secondSectionTxt {
				text-align: center;
				h4 {
					margin: 2em 0.5em 0 0.5em;
					font-size: 1.5rem;
					font-weight: 400;
				}
				.timeTxt {
					margin: 0;
					font-size: 1.5rem;
					font-weight: 800;
				}
				.timeTxt:first-of-type + .timeTxt {
					font-weight: 400;
					text-transform: uppercase;
				}
				.noteTime {
					margin: 0;
					font-size: 1.2rem;
					font-style: italic;
				}
			}

			.thirdSectionTxt {
				text-align: center;
				h4 {
					margin: 2em 0.5em 0 0.5em;
					font-size: 1.5rem;
					font-weight: 400;
				}
				p {
					margin: 0;
					font-size: 1.5rem;
					font-weight: 800;
					span {
						font-weight: 400;
						font-size: 1.5rem;
					}
					span a[href^='tel:'] {
						color: #fff;
					}
				}
				.bottomMail {
					font-weight: 500;
					letter-spacing: 0.1em;
					font-size: 1.5rem;
				}
			}
		}
		.googleMap {
			width: 100%; //
			height: 30vh; //
		}
	}
}

// =========================
//		   ANIMATIONS
// =========================

@keyframes slide {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc(-100% - 1rem));
	}
}

// =========================
//			MEDIA
// =========================

@media (min-width: 768px) {
	.ourOffert {
		.sloganOffert {
			padding: 5em 1em;
			h3 {
				font-size: 2.5rem; //
			}
			.socialText {
				font-size: 2.5rem; //
			}
			.socialBox {
				.socialLink {
					font-size: 2rem;
				}
				.socialText {
					font-size: 2rem;
				}
			}
		}

		.bottomContentOffert {
			.rightContent {
				.topSectionTxt {
					h3 {
						font-size: 2rem;
					}
					.streetName {
						font-size: 4rem;
					}
					.reviewLink {
						font-size: 1.3rem;
					}
				}
				.secondSectionTxt {
					h4 {
						margin: 2em 1.5em 0 1.5em;
						font-size: 2rem;
					}
					.timeTxt {
						font-size: 2rem;
					}
					.noteTime {
						font-size: 1.5rem;
					}
				}

				.thirdSectionTxt {
					h4 {
						margin: 2em 1.5em 0 1.5em;
						font-size: 2rem;
					}
					p {
						font-size: 2rem;
						span {
							font-size: 2rem;
						}
					}
					.bottomMail {
						margin-top: 0.5em;
						letter-spacing: 0.2em;
						font-size: 2rem;
					}
				}
			}
			.googleMap {
				width: 100%;
				height: 30vh;
			}
		}
	}
}
@media (min-width: 992px) {
	.ourOffert {
		.sloganOffert {
			h3 {
				font-size: 3rem;
			}
			.socialText {
				font-size: 3rem;
			}
			.socialBox {
				.socialLink {
					font-size: 2rem;
					.socialText {
						font-size: 2rem;
					}
				}
			}
		}
		.contentOffert {
			.whiteStripeOffert {
				padding: 4em 1em;
				.titleOff {
					h2 {
						font-size: 3rem;
						.textOff {
							font-size: 3rem;
						}
					}
				}
				.contentOff {
					p {
						font-size: 2rem;
					}
				}
			}
		}

		.bottomContentOffert {
			.rightContent {
				.topSectionTxt {
					h3 {
						font-size: 2rem;
					}
					.streetName {
						font-size: 4rem;
					}
					.reviewLink {
						font-size: 1.3rem;
					}
				}
				.secondSectionTxt {
					h4 {
						margin: 2em 1.5em 0 1.5em;
						font-size: 2rem;
					}
					.timeTxt {
						font-size: 2rem;
					}
					.noteTime {
						font-size: 1.5rem;
					}
				}

				.thirdSectionTxt {
					h4 {
						margin: 2em 1.5em 0 1.5em;
						font-size: 2rem;
					}
					p {
						font-size: 2rem;
						span {
							font-size: 2rem;
						}
					}
					.bottomMail {
						margin-top: 0.5em;
						letter-spacing: 0.2em;
						font-size: 2rem;
					}
				}
			}
			.googleMap {
				width: 100%;
				height: 30vh;
			}
		}
	}
}

@media (min-width: 1020px) and (orientation: landscape) {
	.ourOffert {
		.sloganOffert {
			padding: 10em 1em;
			h3 {
				font-size: 2.5rem;
			}
			.socialText {
				font-size: 2rem; //
			}
			.socialBox {
				.socialLink {
					font-size: 1.5rem;
					.socialText {
						font-size: 1.5rem;
					}
				}
			}
		}
		.contentOffert {
			.whiteStripeOffert {
				padding: 3em 3em;
				.titleOff {
					h2 {
						font-size: 2.5rem;
						.textOff {
							font-size: 2.5rem;
						}
					}
				}
				.contentOff {
					p {
						font-size: 1.5rem;
					}
				}
			}
			.carouselSection {
				min-height: 40rem;
				.flexSection {
					display: flex;
					justify-content: center;
					align-items: center;
					.logoElement {
						width: 30rem;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						padding: 0 1em;
						img {
							height: 13rem;
							object-fit: contain;
						}
					}
					.logos {
						min-height: 40rem;
						.logos-slide {
							.slides {
								width: 25rem;
								height: 35rem;
								.graySection {
									.content {
										h3 {
											font-size: 2.2rem;
										}
										p {
											font-size: 1.2rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.bottomContentOffert {
			width: 100%;
			min-height: 30vh;
			display: flex;
			flex-direction: row;
			.rightContent {
				width: 50%; //
				min-height: 100%; //
				display: flex;
				justify-content: center;
				flex-direction: column;

				.topSectionTxt {
					h3 {
						font-size: 2rem;
					}
					.streetName {
						font-size: 4rem;
					}
					.reviewLink {
						font-size: 1rem;
					}
				}
				.secondSectionTxt {
					h4 {
						margin: 2em 4em 0 4em;
						font-size: 1.3rem; //
					}
					.timeTxt {
						font-size: 1.3rem;
					}
					.noteTime {
						font-size: 1rem;
					}
				}

				.thirdSectionTxt {
					h4 {
						margin: 2em 1.5em 0 1.5em;
						font-size: 1.3rem;
					}
					p {
						font-size: 1.3rem;
						span {
							font-size: 1.3rem;
						}
					}
					.bottomMail {
						padding: 1em 0 0 0;
						letter-spacing: 0.2em;
						font-size: 1.3rem;
					}
				}
			}
			.googleMap {
				width: 50%; //
				min-height: 50rem; //
			}
		}
	}
}
@media (min-width: 1800px) and (orientation: landscape) {
	.ourOffert {
		.sloganOffert {
			h3 {
				font-size: 2.5rem;
			}
			.socialText {
				font-size: 2.2rem; //
			}
			.socialBox {
				.socialLink {
					font-size: 1.6rem;
					.socialText {
						font-size: 1.5rem;
					}
				}
			}
		}
		.contentOffert {
			.whiteStripeOffert {
				padding: 5em 2em;
				.titleOff {
					padding: 0 5em 3em 5em;
					h2 {
						font-size: 2.5rem;
						.textOff {
							font-size: 2.5rem;
						}
					}
				}
				.contentOff {
					p {
						padding: 0 5em;
						font-size: 1.7rem;
					}
				}
			}
			.carouselSection {
				min-height: 50rem;
				.flexSection {
					.logoElement {
						width: 35rem;
						img {
							height: 15rem;
						}
					}
					.logos {
						min-height: 50rem;
						.logos-slide {
							.slides {
								width: 30rem;
								height: 40rem;
								.graySection {
									.content {
										h3 {
											font-size: 2.5rem;
										}
										p {
											font-size: 1.5rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.bottomContentOffert {
			.rightContent {
				.topSectionTxt {
					h3 {
						font-size: 2rem;
					}
					.streetName {
						font-size: 4rem;
					}
					.reviewLink {
						font-size: 1.2rem;
					}
				}
				.secondSectionTxt {
					h4 {
						font-size: 1.5rem;
					}
					.timeTxt {
						font-size: 1.5rem;
					}
					.noteTime {
						font-size: 1.3rem;
					}
				}

				.thirdSectionTxt {
					h4 {
						font-size: 1.5rem;
					}
					p {
						font-size: 1.5rem;
						span {
							font-size: 1.5rem;
						}
					}
					.bottomMail {
						padding: 1em 0 0 0;
						font-size: 1.5rem;
					}
				}
			}
			.googleMap {
				min-height: 60rem;
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	.ourOffert {
		.sloganOffert {
			h3 {
				font-size: 3.5rem;
			}
			.socialText {
				font-size: 2.5rem;
			}
			.socialBox {
				.socialLink {
					font-size: 2rem;
					.socialText {
						font-size: 2rem;
					}
				}
			}
		}
		.contentOffert {
			.whiteStripeOffert {
				padding: 5em 2em;
				.titleOff {
					padding: 0 5em 3em 5em;
					h2 {
						font-size: 3.5rem;
						.textOff {
							font-size: 3.5rem;
						}
					}
				}
				.contentOff {
					p {
						padding: 0 5em;
						font-size: 2rem;
					}
				}
			}
			.carouselSection {
				min-height: 60rem;
				.flexSection {
					.logoElement {
						width: 45rem;
						img {
							height: 20rem;
						}
					}
					.logos {
						min-height: 60rem;
						.logos-slide {
							.slides {
								width: 40rem;
								height: 50rem;
								.graySection {
									.content {
										h3 {
											font-size: 3.5rem;
										}
										p {
											font-size: 2rem;
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.bottomContentOffert {
			.rightContent {
				.topSectionTxt {
					h3 {
						font-size: 2.5rem;
					}
					.streetName {
						font-size: 5rem;
					}
					.reviewLink {
						font-size: 1.4rem;
					}
				}
				.secondSectionTxt {
					h4 {
						font-size: 2rem;
					}
					.timeTxt {
						font-size: 2rem;
					}
					.noteTime {
						font-size: 1.5rem;
					}
				}

				.thirdSectionTxt {
					h4 {
						font-size: 2rem;
					}
					p {
						font-size: 2rem;
						span {
							font-size: 2rem;
						}
					}
					.bottomMail {
						padding: 2em 0 0 0;
						font-size: 2rem;
					}
				}
			}
			.googleMap {
				min-height: 60rem;
			}
		}
	}
}

// =======KONTAKT=======

.contact {
	width: 100%;
	min-height: 100vh;
	margin: 0;
	padding: 7em 0;
	background-image: url('../img/offertBgc.jpg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	box-shadow: 0 0 20px #000;
	.bgcBox {
		width: 100%;
		min-height: 90vh;
		background-color: rgba(22, 22, 22, 0.5);
		backdrop-filter: blur(5px);
		box-shadow: 0 0 20px #000;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.headerTxtSection {
			width: 90%;
			display: flex;
			justify-content: space-around;
			align-items: center;
			border: solid 1px #fff;
			border-radius: 15px;
			margin: 3em 0;
			p {
				margin: 0;
				padding: 0.2em 0;
				font-size: 1.5rem;
				color: #fff;
				font-weight: 200;
				text-transform: uppercase;
			}
		}
		.layoutBox {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.topSectionTxt {
				width: 100%;
				padding: 0 2em;
				text-align: center;
				color: #fff;
				h2 {
					font-size: 3rem;
					font-weight: 900;
					text-transform: uppercase;
					padding: 0;
					margin: 0;
				}

				h2:last-of-type {
					padding: 0 0 0.5em 0;
					font-weight: 900;
					-webkit-text-fill-color: transparent;
					-webkit-text-stroke: 1px #fff;
					-webkit-font-smoothing: antialiased;
				}
				p {
					font-family: 'Poppins', sans-serif;
					font-size: 1.3rem;
					font-weight: 300;
					padding: 0.5em 0;
					margin: 0;
				}
			}
			.contactForm {
				padding: 0 3em 2em 3em;
				min-height: 30vh;
				width: 100%;
				// border-radius: 24px;
				.contact-box {
					display: flex;
					flex-direction: column;
					margin: 2em 0;
					.errorForm {
						visibility: hidden;
						font-size: 1.5rem;
						color: red;
						text-transform: uppercase;
						margin-top: 0.3em;
					}
					.errorVisible {
						visibility: visible;
					}
					.checkboxPolitics {
						.checkboxLabel {
							width: 80%;
							font-size: 1.4rem;
						}
						.checkPolitics {
							margin-right: 0.5em;
						}
					}
				}
				label {
					margin-bottom: 0.5em;
					font-size: 1.3rem;
					font-weight: 700;
					color: #fff;
				}
				input,
				textarea,
				button {
					padding: 0.5em;
					font-size: 1.3rem;
					font-family: inherit;
					border-radius: 0.5em;
				}
				input,
				textarea {
					border: 1px solid #fff;
					background-color: rgba(22, 22, 22, 0.3);
					color: #fff;
				}
				input:focus,
				textarea:focus {
					box-shadow: 0 0 20px c.$pink;
					outline: none;
				}
				input::placeholder {
					padding-left: 0.5em;
					color: rgba(255, 255, 255, 0.5);
				}
				textarea {
					resize: vertical;
					min-height: 10em;
					max-height: 35em;
				}
				textarea::placeholder {
					padding-left: 0.5em;
					color: rgba(255, 255, 255, 0.5);
				}
				button {
					width: 10rem;
					color: #fff;
					font-size: 1.3rem;
					text-transform: uppercase;
					text-align: center;
					display: block;
					margin: 0 auto;
					background-color: c.$pink;
					border: none;
					transition: background-color 0.3s, box-shadow 0.3s;
					cursor: pointer;
				}
				button:hover {
					color: #fff;
					box-shadow: 0 0 20px c.$pink;
				}
				button:focus {
					outline: none;
					box-shadow: 0 0 20px c.$pink;
				}
			}
			.msg-status {
				// display: none;
				justify-content: center;
				align-items: center;
				justify-content: center;
				position: absolute;
				top: 65%;
				left: 50%;
				transform: translate(-50%, -50%);
				padding: 0.2em;
				width: 90%;
				height: 4rem; //
				border-radius: 0.7em;
				font-size: 1.5rem;
				color: #fff;
				text-transform: uppercase;
				font-weight: 700;
			}
			.success {
				display: flex;
				background-color: hsl(143, 83%, 55%);
				box-shadow: 0 0 20px hsl(143, 83%, 55%);
			}
			.error {
				display: flex;
				background-color: hsl(9, 100%, 64%);
				box-shadow: 0 0 20px hsl(9, 100%, 64%);
			}
		}
	}
}

// =========================
//			MEDIA
// =========================

@media (min-width: 768px) {
	.contact {
		display: flex;
		justify-content: center;
		align-items: center;
		.bgcBox {
			width: 90%; //
			border-radius: 15px;
			min-height: 80vh;
			.headerTxtSection {
				margin: 3em 0 7em 0;
				p {
					font-size: 1.8rem;
				}
			}
			.layoutBox {
				.topSectionTxt {
					h2 {
						font-size: 3.5rem;
					}
					p {
						font-size: 1.5rem;
					}
				}
				.contactForm {
					label {
						font-size: 1.5rem;
					}
					input,
					textarea,
					button {
						font-size: 1.5rem;
					}
					textarea {
						min-height: 15em;
					}
					button {
						width: 15rem;
						font-size: 1.5rem;
					}
				}
			}
		}
	}
}
@media (min-width: 992px) {
	.contact {
		.bgcBox {
			width: 90%; //
			border-radius: 15px;
			min-height: 80vh;
			.headerTxtSection {
				margin: 3em 0 7em 0;
				p {
					font-size: 2.2rem;
				}
			}
			.layoutBox {
				.topSectionTxt {
					h2 {
						font-size: 4rem;
					}
					p {
						padding: 0.5em 3em;
						font-size: 2.2rem;
					}
				}
				.contactForm {
					label {
						font-size: 2.2rem;
					}
					input,
					textarea {
						font-size: 2.2rem;
					}
					textarea {
						min-height: 15em;
					}
					button {
						width: 15rem;
						font-size: 1.8rem;
					}
				}
				.msg-status {
					height: 6rem;
					font-size: 2.2rem;
				}
			}
		}
	}
}
@media (min-width: 1020px) and (orientation: landscape) {
	.contact {
		.bgcBox {
			.headerTxtSection {
				p {
					font-size: 1.7rem;
				}
			}
			.layoutBox {
				.topSectionTxt {
					p {
						font-size: 1.8rem;
					}
				}
				.contactForm {
					label {
						font-size: 1.8rem;
					}
					input,
					textarea {
						font-size: 1.8rem;
					}
					textarea {
						min-height: 10em;
					}
					button {
						width: 10rem;
						font-size: 1.5rem;
					}
				}
				.msg-status {
					height: 4rem;
					font-size: 1.8rem;
				}
			}
		}
	}
}
@media (min-width: 1800px) and (orientation: landscape) {
	.contact {
		display: flex; //
		justify-content: center; //
		align-items: center; //
		.bgcBox {
			width: 90%; //
			min-height: 80vh; //
			.headerTxtSection {
				margin: 3em 0 5em 0; //
				p {
					font-size: 2rem; //
				}
			}
			.layoutBox {
				flex-direction: row; //
				.topSectionTxt {
					h2 {
						font-size: 4rem; //
					}
					p {
						font-size: 1.8rem; //
						padding: 0.5em 4em; //
					}
				}
				.contactForm {
					padding: 0 10em 2em 0; //
					label {
						font-size: 1.5rem; //
					}
					input,
					textarea {
						font-size: 1.5rem; //
					}
					textarea {
						min-height: 15em; //
					}
					button {
						width: 15rem; //
						font-size: 1.3rem; //
					}
				}
				.msg-status {
					left: 72%; //
					width: 45%; //
					height: 4rem; //
					font-size: 1.5rem; //
				}
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	.contact {
		.bgcBox {
			.headerTxtSection {
				p {
					font-size: 2.5rem; //
				}
			}
			.layoutBox {
				.topSectionTxt {
					h2 {
						font-size: 5.5rem; //
					}
					p {
						font-size: 2rem; //
					}
				}
				.contactForm {
					padding: 0 15em 2em 0; //
					label {
						font-size: 2rem; //
					}
					input,
					textarea {
						font-size: 2rem; //
					}
					button {
						font-size: 1.5rem; //
					}
				}
				.msg-status {
					left: 71%; //
					height: 5rem; //
					font-size: 1.8rem; //
				}
			}
		}
	}
}

// =======STOPKA=======

footer {
	width: 100%;
	min-height: 10em;
	padding: 2em 1em;
	background-color: #333;
	display: flex;
	justify-content: center;
	// justify-content: space-around;
	align-items: center;
	flex-direction: column;
	// flex-direction: row;
	.left-section {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		color: #fff;
		img {
			width: 9em;
			padding-bottom: 0.5em;
		}
		.copyrights {
			margin: 0;
			font-size: 1.5rem;
			font-weight: 500;
			text-align: center;
			span {
				color: c.$pink;
				font-weight: 600;
			}
			.date {
				color: #fff;
				font-weight: 500;
			}
		}
		.createdby {
			margin: 0;
			font-size: 1.2rem;
			font-weight: 200;
			a {
				color: #fff;
				text-decoration: none;
				transition: color 0.3s;
				font-weight: 400;
			}
			a:hover,
			a:focus {
				outline: none;
				color: c.$pink;
			}
			a:visited {
				color: #fff;
				text-decoration: none;
			}
		}
	}
	.right-section {
		width: 100%;
		padding: 2em 0 0 0;
		display: flex;
		flex-direction: column;
		color: #fff;
		text-align: center;
		h4 {
			margin: 0;
			padding-bottom: 0.5em;
			font-size: 1.3rem;
		}
		p {
			margin: 0;
			font-size: 1.3rem;
			font-weight: 200;
		}
		.regulations {
			padding-top: 1em;
			font-size: 1.3rem;
			a {
				text-decoration: none;
				color: #fff;
				font-weight: 200;
			}
			a:hover {
				font-weight: 400;
			}
			a:visited {
				text-decoration: none;
				color: #fff;
			}
		}
	}
}

// =========================
//			MEDIA
// =========================

@media (min-width: 768px) {
	footer {
		justify-content: space-around; //
		flex-direction: row; //
		.left-section {
			width: 50%; //
			img {
				width: 12em; //
				padding-bottom: 1em; //
			}
			// .copyrights {
			// 	font-size: 1.5rem;
			// }
			// .createdby {
			// 	font-size: 1.2rem;
			// }
		}
		.right-section {
			width: 50%; //
			padding: 2em 0 2em 0; //
			// h4 {
			// 	padding-bottom: .5em;
			// 	font-size: 1.3rem;
			// }
			// p {
			// 	font-size: 1.3rem;
			// }
			// .regulations {
			// 	padding-top: 1em;
			// 	font-size: 1.3rem;
			// }
		}
	}
}
@media (min-width: 1020px) and (orientation: landscape) {
	footer {
		padding: 2em 20em;
		.left-section {
			img {
				width: 15em; //
			}
			.copyrights {
				font-size: 1.8rem;
			}
			.createdby {
				font-size: 1.4rem;
			}
		}
		.right-section {
			h4 {
				font-size: 1.5rem;
			}
			p {
				font-size: 1.5rem;
			}
			.regulations {
				font-size: 1.5rem;
			}
		}
	}
}
@media (min-width: 1800px) and (orientation: landscape) {
	footer {
		padding: 2em 50em;
		.left-section {
			img {
				width: 10em; //
			}
			.copyrights {
				font-size: 1.5rem; //
			}
			.createdby {
				font-size: 1.2rem; //
			}
		}
		.right-section {
			h4 {
				font-size: 1.5rem;
			}
			p {
				font-size: 1.5rem;
			}
			.regulations {
				font-size: 1.3rem;
			}
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	footer {
		padding: 2em 80em;
		.left-section {
			img {
				width: 13em; //
			}
			.copyrights {
				font-size: 1.8rem;
			}
			.createdby {
				font-size: 1.4rem;
			}
		}
		.right-section {
			h4 {
				font-size: 1.7rem;
			}
			p {
				font-size: 1.7rem;
			}
			.regulations {
				font-size: 1.4rem;
			}
		}
	}
}
