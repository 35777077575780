.cookie-box {
	position: fixed;
	bottom: 0;
	padding: 1em;
	margin: 0;
	min-height: 5vh;
	width: 100%;
	text-align: center;
	font-size: 1.5rem;
	font-weight: 200;
	color: #fff;
	background-color: rgba(11, 11, 11, 0.7);
	opacity: 0;
	transform: translateY(100%);
	animation: showCookies 0.5s 0.5s forwards;
	z-index: 100;
	.cookie-btn {
		margin-top: 0.2em;
		padding: 0.5em 1em;
		font-size: 1rem;
		color: #fff;
		text-transform: uppercase;
		border: none;
		border-radius: 10px;
		background-color: c.$pink;
		transition: background-color 0.3s;
	}
	.cookie-btn:hover {
		background-color: c.$btnHover;
	}
}
.hide {
	display: none;
}

// =========================
//		   ANIMATIONS
// =========================

@keyframes showCookies {
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

// =========================
//			MEDIA
// =========================

@media (min-width: 1020px) {
	.cookie-box {
		font-size: 2.2rem;
		.cookie-btn {
			font-size: 1.5rem;
		}
	}
}
@media (min-width: 1020px) and (orientation: landscape) {
	.cookie-box {
		font-size: 1.5rem;
		.cookie-btn {
			font-size: 1rem;
		}
	}
}
@media (min-width: 1800px) and (orientation: landscape) {
	.cookie-box {
		font-size: 2rem;
		.cookie-btn {
			font-size: 1.3rem;
		}
	}
}
@media (min-width: 2500px) and (orientation: landscape) {
	.cookie-box {
		font-size: 2.5rem;
		.cookie-btn {
			font-size: 1.5rem;
		}
	}
}
