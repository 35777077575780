.cookie-box {
  position: fixed;
  bottom: 0;
  padding: 1em;
  margin: 0;
  min-height: 5vh;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 200;
  color: #fff;
  background-color: rgba(11, 11, 11, 0.7);
  opacity: 0;
  transform: translateY(100%);
  animation: showCookies 0.5s 0.5s forwards;
  z-index: 100;
}
.cookie-box .cookie-btn {
  margin-top: 0.2em;
  padding: 0.5em 1em;
  font-size: 1rem;
  color: #fff;
  text-transform: uppercase;
  border: none;
  border-radius: 10px;
  background-color: #d63a94;
  transition: background-color 0.3s;
}
.cookie-box .cookie-btn:hover {
  background-color: #7b1d54;
}

.hide {
  display: none;
}

@keyframes showCookies {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media (min-width: 1020px) {
  .cookie-box {
    font-size: 2.2rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1.5rem;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  .cookie-box {
    font-size: 1.5rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  .cookie-box {
    font-size: 2rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1.3rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .cookie-box {
    font-size: 2.5rem;
  }
  .cookie-box .cookie-btn {
    font-size: 1.5rem;
  }
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

::selection {
  background-color: #d63a94;
  color: #fff;
}

::-webkit-scrollbar {
  width: 1.5rem;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #d63a94);
  border-radius: 100vw;
  border: 0.25em solid #333;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(transparent, #b12777);
}

::-webkit-scrollbar-track {
  background: #333;
}

body {
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}

.fadeIn {
  transform: translateY(20px);
  opacity: 0;
  transition: 1s ease;
}

.fadeIn--visible {
  transform: translateY(0);
  opacity: 1;
}

.navbar {
  min-height: 5em;
}
.navbar .navbar-toggler {
  border: none;
  outline: none;
  background-color: rgba(33, 33, 33, 0.5);
  padding: 0.5em;
  border-radius: 10px;
}
.navbar .navbar-toggler .navbar-toggler-icon .fa-bars {
  color: #fff;
  font-size: 2rem;
}
.navbar .offcanvas {
  background-color: #000;
}
.navbar .offcanvas .offcanvas-header .btn-close .fa-xmark {
  color: #fff;
  font-size: 2rem;
}
.navbar .offcanvas .offcanvas-body {
  margin-top: 5em;
}
.navbar .offcanvas .offcanvas-body .nav-item .nav-link {
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 0.2em;
}
.navbar .offcanvas .offcanvas-body .nav-item .nav-link:hover {
  color: #b12777;
}
.navbar .offcanvas .offcanvas-body .nav-item .nav-link:focus {
  box-shadow: none;
  color: #b12777;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu {
  border: none;
  box-shadow: 0 0 15px gray;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
  font-size: 1.5rem;
  padding: 0.5em;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item:hover {
  color: #fff;
  background-color: #b12777;
}
.navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item:focus {
  outline: none;
  color: #fff;
  background-color: #b12777;
}

@media (min-width: 768px) {
  .navbar .navbar-toggler {
    margin-top: 1em;
    padding: 1em 1.2em 1.2em 1em;
    border-radius: 13px;
  }
  .navbar .navbar-toggler .navbar-toggler-icon .fa-bars {
    font-size: 2.5rem;
  }
  .navbar .offcanvas .offcanvas-header .btn-close .fa-xmark {
    font-size: 2.5rem;
  }
  .navbar .offcanvas .offcanvas-body {
    margin-top: 7em;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .nav-link {
    font-size: 3.5rem;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .navbar {
    min-height: 5em;
    background-color: rgba(33, 33, 33, 0.7);
    color: #fff;
  }
  .navbar .offcanvas .offcanvas-body {
    margin-top: 0;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .nav-link {
    text-transform: none;
    color: white;
    font-size: 1.7rem;
    font-weight: 300;
    padding: 0 1em;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
    font-size: 1.5rem;
    padding: 0.5em;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .navbar {
    min-height: 7em;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .nav-link {
    font-size: 2rem;
  }
  .navbar .offcanvas .offcanvas-body .nav-item .dropdown-menu .dropdown-item {
    font-size: 1.8rem;
  }
}
header {
  position: relative;
}
header .home-background {
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}
header .home-background .home-video {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  object-fit: cover;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: none;
}
header .home-background .home-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  padding: 0 1em;
  font-family: "Poppins", sans-serif;
  text-align: center;
  color: #fff;
  text-shadow: 0 0 10px #000;
}
header .home-background .home-text .nonVisible {
  display: none;
}
header .home-background .home-text img {
  width: 30rem;
  opacity: 0.6;
  filter: drop-shadow(0 0 15px rgba(255, 255, 255, 0.8));
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  z-index: -1;
}
header .home-background .home-text .headTxt {
  font-size: 3.2rem;
  font-weight: 700;
  padding-bottom: 10px;
  letter-spacing: -1px;
}
header .home-background .home-text .headTxt span {
  color: #d63a94;
}
header .home-background .home-text .headerText {
  font-size: 1.5rem;
  font-weight: 100;
  margin-top: -5px;
}
header .home-background .headerBtn {
  padding: 0.5em 1em;
  margin-top: 1em;
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  border-radius: 10px;
  border: none;
  background-color: #d63a94;
  transition: background-color 0.3s;
}
header .home-background .headerBtn:hover {
  background-color: #7b1d54;
}
header .home-background .headerBtn:focus {
  outline: none;
  background-color: #7b1d54;
}

@media (min-width: 768px) {
  header .home-background .home-text img {
    width: 70rem;
  }
  header .home-background .home-text .headTxt {
    font-size: 6rem;
  }
  header .home-background .home-text .headerText {
    font-size: 2.5rem;
  }
  header .home-background .headerBtn {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  header .home-background .home-text img {
    width: 60vw;
  }
  header .home-background .home-text .headTxt {
    font-size: 7rem;
  }
  header .home-background .home-text .headerText {
    font-size: 2.5rem;
  }
}
@media (min-width: 1020px) {
  header .home-background .home-text img {
    width: 90rem;
  }
  header .home-background .home-text .headTxt {
    font-size: 7rem;
  }
  header .home-background .home-text .headerText {
    font-size: 3rem;
  }
  header .home-background .headerBtn {
    font-size: 1.5rem;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  header .home-background .home-text img {
    width: 80rem;
  }
  header .home-background .home-text .headTxt {
    font-size: 5.5rem;
  }
  header .home-background .home-text .headerText {
    font-size: 2.2rem;
  }
  header .home-background .headerBtn {
    font-size: 1.3rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  header .home-background .home-video {
    position: fixed;
  }
  header .home-background .home-text img {
    width: 110rem;
  }
  header .home-background .home-text .headTxt {
    font-size: 6rem;
  }
  header .home-background .home-text .headerText {
    font-size: 3rem;
  }
  header .home-background .headerBtn {
    font-size: 1.5rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  header .home-background .home-text img {
    width: 150rem;
  }
  header .home-background .home-text .headTxt {
    font-size: 10rem;
  }
  header .home-background .home-text .headerText {
    font-size: 4rem;
  }
  header .home-background .headerBtn {
    font-size: 2rem;
  }
}
.aboutus {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.aboutus .flexSection {
  display: grid;
  grid-template-rows: 1fr;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.aboutus .flexSection .bgcLeft {
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 50px black;
  z-index: 5;
}
.aboutus .flexSection .bgcLeft .abtLeft {
  display: flex;
  align-items: center;
  height: 100%;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs {
  padding: 7em 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs {
  display: flex;
  align-items: center;
  margin-bottom: 3em;
  padding-left: 1em;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs .stripeAboutUs {
  width: 2.5em;
  height: 0.2em;
  background-color: #b12777;
  border-radius: 5px;
  rotate: 90deg;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 span {
  font-size: 2rem;
  font-weight: 700;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs p {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-indent: 2rem;
  padding: 0 1em;
  margin: 0;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs p strong {
  font-weight: 400;
}
.aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .bottomAbt {
  margin-top: 2em;
}
.aboutus .flexSection .abtRight {
  min-height: 60vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: background-image 1.5s ease-in-out;
}
.aboutus .flexSection .abtRight.bg1 {
  background-image: url("../img/firstOwner.jpg");
}
.aboutus .flexSection .abtRight.bg2 {
  background-image: url("../img/sittingTogether.jpg");
}
.aboutus .flexSection .abtRight.bg3 {
  background-image: url("../img/secondOwner.jpg");
}
.aboutus .flexSection .abtRight.bg4 {
  background-image: url("../img/efektOffice.jpg");
}
.aboutus .flexSection .abtRight.bg5 {
  background-image: url("../img/takingLeaflet.jpg");
}
.aboutus .flexSection .abtRight .abtRightContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 2;
}
.aboutus .bottomStripe {
  width: 100%;
  min-height: 5rem;
  background-color: #000;
  overflow: hidden;
  white-space: nowrap;
  z-index: 6;
  position: absolute;
  top: 100%;
  left: 0%;
  transform: translate(0%, -100%);
}
.aboutus .bottomStripe .stripeContent {
  display: inline-block;
  animation: 35s patrons infinite linear;
}
.aboutus .bottomStripe .stripeContent img {
  height: 5rem;
  margin: 0 15px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

@keyframes bgFade {
  0%, 12% {
    opacity: 1;
  }
  13%, 20% {
    opacity: 0;
  }
  21%, 32% {
    opacity: 1;
  }
  33%, 40% {
    opacity: 0;
  }
  41%, 52% {
    opacity: 1;
  }
  53%, 60% {
    opacity: 0;
  }
  61%, 72% {
    opacity: 1;
  }
  73%, 80% {
    opacity: 0;
  }
  81%, 92% {
    opacity: 1;
  }
  93%, 100% {
    opacity: 0;
  }
}
@keyframes patrons {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@media (min-width: 768px) {
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs {
    padding: 10em 3em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs {
    margin-bottom: 4em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs .stripeAboutUs {
    width: 5em;
    height: 0.3em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 {
    font-size: 3rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 span {
    font-size: 3rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs p {
    font-size: 1.7rem;
  }
  .aboutus .bottomStripe {
    min-height: 7rem;
  }
  .aboutus .bottomStripe .stripeContent img {
    height: 7rem;
  }
}
@media (min-width: 992px) {
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs {
    padding: 10em 3em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs {
    margin-bottom: 4em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs .stripeAboutUs {
    width: 5em;
    height: 0.3em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 {
    font-size: 3.5rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 span {
    font-size: 3.5rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs p {
    font-size: 1.8rem;
  }
  .aboutus .bottomStripe {
    min-height: 7rem;
  }
  .aboutus .bottomStripe .stripeContent img {
    height: 7rem;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  .aboutus .flexSection {
    grid-template-columns: 1fr 1fr;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs {
    padding: 7em 2em 7em 2em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs {
    margin-bottom: 3em;
    padding-left: 1em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs .stripeAboutUs {
    width: 3em;
    height: 0.2em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 {
    font-size: 2.2rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 span {
    font-size: 2.2rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs p {
    font-size: 1.4rem;
    padding: 0 2em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .bottomAbt {
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .aboutus .flexSection .abtRight {
    height: 100%;
  }
  .aboutus .bottomStripe {
    min-height: 5rem;
  }
  .aboutus .bottomStripe .stripeContent img {
    height: 5rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs .stripeAboutUs {
    width: 4em;
    height: 0.3em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 {
    font-size: 3rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 span {
    font-size: 3rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs p {
    font-size: 1.7rem;
    padding: 0 2em;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs {
    padding-bottom: 3em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs .stripeAboutUs {
    width: 6em;
    height: 0.3em;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 {
    font-size: 4rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs .headerAbtUs h2 span {
    font-size: 4rem;
  }
  .aboutus .flexSection .bgcLeft .abtLeft .headerAboutUs p {
    font-size: 2.2rem;
  }
  .aboutus .bottomStripe {
    min-height: 7rem;
  }
  .aboutus .bottomStripe .stripeContent img {
    height: 7rem;
  }
}
.ourOffert {
  width: 100%;
  min-height: 100vh;
}
.ourOffert .sloganOffert {
  min-height: 30vh;
  width: 100%;
  padding: 7em 1em;
  background-color: #d63a94;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.ourOffert .sloganOffert h3 {
  width: 100%;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0 0 30px #000;
}
.ourOffert .sloganOffert .socialText {
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 2rem;
  font-weight: 200;
  text-align: center;
  margin: 0.5em 2em 0.5em 2em;
}
.ourOffert .sloganOffert .socialBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ourOffert .sloganOffert .socialBox .socialLink {
  font-size: 1.5rem;
  background-color: rgba(177, 39, 119, 0.7);
  border-radius: 5px;
  padding: 0.5em 1em;
  margin: 0;
}
.ourOffert .sloganOffert .socialBox .socialLink a:link,
.ourOffert .sloganOffert .socialBox .socialLink a:visited {
  color: #fff;
  text-decoration: none;
}
.ourOffert .sloganOffert .socialBox .socialLink a:hover {
  color: #000;
}
.ourOffert .sloganOffert .socialBox .socialLink a:hover .socialText {
  color: #000;
}
.ourOffert .sloganOffert .socialBox .socialLink a:focus {
  outline: none;
  color: #000;
}
.ourOffert .sloganOffert .socialBox .socialLink a:focus .socialText {
  color: #000;
}
.ourOffert .sloganOffert .socialBox .socialLink .socialText {
  font-size: 1.5rem;
  margin: 0 0 0 0.5em;
  font-weight: 400;
}
.ourOffert .sloganOffert .socialBox .socialLink:first-of-type + .socialLink {
  margin-left: 2em;
}
.ourOffert .contentOffert {
  width: 100%;
}
.ourOffert .contentOffert .whiteStripeOffert {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2em 1em;
  position: relative;
  box-shadow: 0 0 20px #000;
  background-color: #fff;
  z-index: 3;
}
.ourOffert .contentOffert .whiteStripeOffert .titleOff {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2em 0;
}
.ourOffert .contentOffert .whiteStripeOffert .titleOff h2 {
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: 300;
  margin: 0;
}
.ourOffert .contentOffert .whiteStripeOffert .titleOff h2 .textOff {
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
}
.ourOffert .contentOffert .whiteStripeOffert .titleOff .stripeOff {
  width: 4em;
  height: 0.2em;
  background-color: #b12777;
  border-radius: 5px;
  rotate: 90deg;
}
.ourOffert .contentOffert .whiteStripeOffert .contentOff {
  padding: 0 2em;
}
.ourOffert .contentOffert .whiteStripeOffert .contentOff p {
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  text-indent: 2em;
  margin: 0;
}
.ourOffert .contentOffert .whiteStripeOffert .contentOff p:first-of-type + p {
  margin: 2em 0;
}
.ourOffert .contentOffert .carouselSection {
  width: 100%;
  min-height: 50rem;
  background-color: #333;
}
.ourOffert .contentOffert .carouselSection .flexSection .logoElement {
  display: none;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos {
  width: 100%;
  min-height: 50rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide {
  display: inline-block;
  animation: slide 80s linear infinite;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides {
  display: inline-block;
  width: 30rem;
  height: 40rem;
  margin: 0 1em;
  box-shadow: 0 0 5px #fff;
  border-radius: 15px;
  background-size: cover;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  overflow: hidden;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  border-radius: 15px;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content h3 {
  padding: 1em 0 0.5em 0;
  margin: 0;
  color: white;
  font-weight: 700;
  font-size: 3rem;
  text-shadow: 0 0 10px #000;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content p {
  width: 100%;
  text-wrap: wrap;
  color: white;
  padding: 0.5em 0.6em;
  margin: 0;
  font-weight: 300;
  font-size: 1.6rem;
  text-shadow: 0 0 10px #000;
  text-align: center;
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide1 {
  background-image: url(../img/slide1.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide2 {
  background-image: url(../img/slide2.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide3 {
  background-image: url(../img/slide3.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide4 {
  background-image: url(../img/slide4.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide5 {
  background-image: url(../img/slide5.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide6 {
  background-image: url(../img/slide6.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide7 {
  background-image: url(../img/slide7.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide8 {
  background-image: url(../img/slide8.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide9 {
  background-image: url(../img/slide9.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide10 {
  background-image: url(../img/slide10.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide11 {
  background-image: url(../img/slide11.jpg);
}
.ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slide12 {
  background-image: url(../img/slide12.jpg);
}
.ourOffert .contentOffert .carouselSection .logos:hover .logos-slide {
  animation-play-state: paused;
}
.ourOffert .bottomContentOffert {
  background-color: #fff;
  box-shadow: 0 0 20px #000;
}
.ourOffert .bottomContentOffert .rightContent {
  padding: 3em 1em;
  font-family: "Poppins", sans-serif;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: uppercase;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt .streetName {
  font-size: 3.5rem;
  font-weight: 800;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink {
  font-size: 1.2rem;
  text-transform: uppercase;
  background-color: #d63a94;
  border-radius: 5px;
  margin: 0;
  transition: background-color 0.3s;
  cursor: pointer;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink a {
  padding: 1em 2em;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink a:link,
.ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink a:visited {
  color: #fff;
  text-decoration: none;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink a:focus {
  outline: none;
  color: #000;
}
.ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink:hover {
  background-color: #7b1d54;
}
.ourOffert .bottomContentOffert .rightContent .secondSectionTxt {
  text-align: center;
}
.ourOffert .bottomContentOffert .rightContent .secondSectionTxt h4 {
  margin: 2em 0.5em 0 0.5em;
  font-size: 1.5rem;
  font-weight: 400;
}
.ourOffert .bottomContentOffert .rightContent .secondSectionTxt .timeTxt {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
}
.ourOffert .bottomContentOffert .rightContent .secondSectionTxt .timeTxt:first-of-type + .timeTxt {
  font-weight: 400;
  text-transform: uppercase;
}
.ourOffert .bottomContentOffert .rightContent .secondSectionTxt .noteTime {
  margin: 0;
  font-size: 1.2rem;
  font-style: italic;
}
.ourOffert .bottomContentOffert .rightContent .thirdSectionTxt {
  text-align: center;
}
.ourOffert .bottomContentOffert .rightContent .thirdSectionTxt h4 {
  margin: 2em 0.5em 0 0.5em;
  font-size: 1.5rem;
  font-weight: 400;
}
.ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 800;
}
.ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p span {
  font-weight: 400;
  font-size: 1.5rem;
}
.ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p span a[href^="tel:"] {
  color: #fff;
}
.ourOffert .bottomContentOffert .rightContent .thirdSectionTxt .bottomMail {
  font-weight: 500;
  letter-spacing: 0.1em;
  font-size: 1.5rem;
}
.ourOffert .bottomContentOffert .googleMap {
  width: 100%;
  height: 30vh;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 1rem));
  }
}
@media (min-width: 768px) {
  .ourOffert .sloganOffert {
    padding: 5em 1em;
  }
  .ourOffert .sloganOffert h3 {
    font-size: 2.5rem;
  }
  .ourOffert .sloganOffert .socialText {
    font-size: 2.5rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink {
    font-size: 2rem;
  }
  .ourOffert .sloganOffert .socialBox .socialText {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt h3 {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .streetName {
    font-size: 4rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink {
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt h4 {
    margin: 2em 1.5em 0 1.5em;
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .timeTxt {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .noteTime {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt h4 {
    margin: 2em 1.5em 0 1.5em;
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p span {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt .bottomMail {
    margin-top: 0.5em;
    letter-spacing: 0.2em;
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .googleMap {
    width: 100%;
    height: 30vh;
  }
}
@media (min-width: 992px) {
  .ourOffert .sloganOffert h3 {
    font-size: 3rem;
  }
  .ourOffert .sloganOffert .socialText {
    font-size: 3rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink {
    font-size: 2rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink .socialText {
    font-size: 2rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert {
    padding: 4em 1em;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 {
    font-size: 3rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 .textOff {
    font-size: 3rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .contentOff p {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt h3 {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .streetName {
    font-size: 4rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink {
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt h4 {
    margin: 2em 1.5em 0 1.5em;
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .timeTxt {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .noteTime {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt h4 {
    margin: 2em 1.5em 0 1.5em;
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p span {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt .bottomMail {
    margin-top: 0.5em;
    letter-spacing: 0.2em;
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .googleMap {
    width: 100%;
    height: 30vh;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  .ourOffert .sloganOffert {
    padding: 10em 1em;
  }
  .ourOffert .sloganOffert h3 {
    font-size: 2.5rem;
  }
  .ourOffert .sloganOffert .socialText {
    font-size: 2rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink {
    font-size: 1.5rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink .socialText {
    font-size: 1.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert {
    padding: 3em 3em;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 {
    font-size: 2.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 .textOff {
    font-size: 2.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .contentOff p {
    font-size: 1.5rem;
  }
  .ourOffert .contentOffert .carouselSection {
    min-height: 40rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logoElement {
    width: 30rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1em;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logoElement img {
    height: 13rem;
    object-fit: contain;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos {
    min-height: 40rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides {
    width: 25rem;
    height: 35rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content h3 {
    font-size: 2.2rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content p {
    font-size: 1.2rem;
  }
  .ourOffert .bottomContentOffert {
    width: 100%;
    min-height: 30vh;
    display: flex;
    flex-direction: row;
  }
  .ourOffert .bottomContentOffert .rightContent {
    width: 50%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt h3 {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .streetName {
    font-size: 4rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink {
    font-size: 1rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt h4 {
    margin: 2em 4em 0 4em;
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .timeTxt {
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .noteTime {
    font-size: 1rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt h4 {
    margin: 2em 1.5em 0 1.5em;
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p {
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p span {
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt .bottomMail {
    padding: 1em 0 0 0;
    letter-spacing: 0.2em;
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .googleMap {
    width: 50%;
    min-height: 50rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  .ourOffert .sloganOffert h3 {
    font-size: 2.5rem;
  }
  .ourOffert .sloganOffert .socialText {
    font-size: 2.2rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink {
    font-size: 1.6rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink .socialText {
    font-size: 1.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert {
    padding: 5em 2em;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff {
    padding: 0 5em 3em 5em;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 {
    font-size: 2.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 .textOff {
    font-size: 2.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .contentOff p {
    padding: 0 5em;
    font-size: 1.7rem;
  }
  .ourOffert .contentOffert .carouselSection {
    min-height: 50rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logoElement {
    width: 35rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logoElement img {
    height: 15rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos {
    min-height: 50rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides {
    width: 30rem;
    height: 40rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content h3 {
    font-size: 2.5rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content p {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt h3 {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .streetName {
    font-size: 4rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink {
    font-size: 1.2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt h4 {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .timeTxt {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .noteTime {
    font-size: 1.3rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt h4 {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p span {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt .bottomMail {
    padding: 1em 0 0 0;
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .googleMap {
    min-height: 60rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .ourOffert .sloganOffert h3 {
    font-size: 3.5rem;
  }
  .ourOffert .sloganOffert .socialText {
    font-size: 2.5rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink {
    font-size: 2rem;
  }
  .ourOffert .sloganOffert .socialBox .socialLink .socialText {
    font-size: 2rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert {
    padding: 5em 2em;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff {
    padding: 0 5em 3em 5em;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 {
    font-size: 3.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .titleOff h2 .textOff {
    font-size: 3.5rem;
  }
  .ourOffert .contentOffert .whiteStripeOffert .contentOff p {
    padding: 0 5em;
    font-size: 2rem;
  }
  .ourOffert .contentOffert .carouselSection {
    min-height: 60rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logoElement {
    width: 45rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logoElement img {
    height: 20rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos {
    min-height: 60rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides {
    width: 40rem;
    height: 50rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content h3 {
    font-size: 3.5rem;
  }
  .ourOffert .contentOffert .carouselSection .flexSection .logos .logos-slide .slides .graySection .content p {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt h3 {
    font-size: 2.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .streetName {
    font-size: 5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .topSectionTxt .reviewLink {
    font-size: 1.4rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt h4 {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .timeTxt {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .secondSectionTxt .noteTime {
    font-size: 1.5rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt h4 {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt p span {
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .rightContent .thirdSectionTxt .bottomMail {
    padding: 2em 0 0 0;
    font-size: 2rem;
  }
  .ourOffert .bottomContentOffert .googleMap {
    min-height: 60rem;
  }
}
.contact {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 7em 0;
  background-image: url("../img/offertBgc.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  box-shadow: 0 0 20px #000;
}
.contact .bgcBox {
  width: 100%;
  min-height: 90vh;
  background-color: rgba(22, 22, 22, 0.5);
  backdrop-filter: blur(5px);
  box-shadow: 0 0 20px #000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.contact .bgcBox .headerTxtSection {
  width: 90%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: solid 1px #fff;
  border-radius: 15px;
  margin: 3em 0;
}
.contact .bgcBox .headerTxtSection p {
  margin: 0;
  padding: 0.2em 0;
  font-size: 1.5rem;
  color: #fff;
  font-weight: 200;
  text-transform: uppercase;
}
.contact .bgcBox .layoutBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact .bgcBox .layoutBox .topSectionTxt {
  width: 100%;
  padding: 0 2em;
  text-align: center;
  color: #fff;
}
.contact .bgcBox .layoutBox .topSectionTxt h2 {
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.contact .bgcBox .layoutBox .topSectionTxt h2:last-of-type {
  padding: 0 0 0.5em 0;
  font-weight: 900;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px #fff;
  -webkit-font-smoothing: antialiased;
}
.contact .bgcBox .layoutBox .topSectionTxt p {
  font-family: "Poppins", sans-serif;
  font-size: 1.3rem;
  font-weight: 300;
  padding: 0.5em 0;
  margin: 0;
}
.contact .bgcBox .layoutBox .contactForm {
  padding: 0 3em 2em 3em;
  min-height: 30vh;
  width: 100%;
}
.contact .bgcBox .layoutBox .contactForm .contact-box {
  display: flex;
  flex-direction: column;
  margin: 2em 0;
}
.contact .bgcBox .layoutBox .contactForm .contact-box .errorForm {
  visibility: hidden;
  font-size: 1.5rem;
  color: red;
  text-transform: uppercase;
  margin-top: 0.3em;
}
.contact .bgcBox .layoutBox .contactForm .contact-box .errorVisible {
  visibility: visible;
}
.contact .bgcBox .layoutBox .contactForm .contact-box .checkboxPolitics .checkboxLabel {
  width: 80%;
  font-size: 1.4rem;
}
.contact .bgcBox .layoutBox .contactForm .contact-box .checkboxPolitics .checkPolitics {
  margin-right: 0.5em;
}
.contact .bgcBox .layoutBox .contactForm label {
  margin-bottom: 0.5em;
  font-size: 1.3rem;
  font-weight: 700;
  color: #fff;
}
.contact .bgcBox .layoutBox .contactForm input,
.contact .bgcBox .layoutBox .contactForm textarea,
.contact .bgcBox .layoutBox .contactForm button {
  padding: 0.5em;
  font-size: 1.3rem;
  font-family: inherit;
  border-radius: 0.5em;
}
.contact .bgcBox .layoutBox .contactForm input,
.contact .bgcBox .layoutBox .contactForm textarea {
  border: 1px solid #fff;
  background-color: rgba(22, 22, 22, 0.3);
  color: #fff;
}
.contact .bgcBox .layoutBox .contactForm input:focus,
.contact .bgcBox .layoutBox .contactForm textarea:focus {
  box-shadow: 0 0 20px #d63a94;
  outline: none;
}
.contact .bgcBox .layoutBox .contactForm input::placeholder {
  padding-left: 0.5em;
  color: rgba(255, 255, 255, 0.5);
}
.contact .bgcBox .layoutBox .contactForm textarea {
  resize: vertical;
  min-height: 10em;
  max-height: 35em;
}
.contact .bgcBox .layoutBox .contactForm textarea::placeholder {
  padding-left: 0.5em;
  color: rgba(255, 255, 255, 0.5);
}
.contact .bgcBox .layoutBox .contactForm button {
  width: 10rem;
  color: #fff;
  font-size: 1.3rem;
  text-transform: uppercase;
  text-align: center;
  display: block;
  margin: 0 auto;
  background-color: #d63a94;
  border: none;
  transition: background-color 0.3s, box-shadow 0.3s;
  cursor: pointer;
}
.contact .bgcBox .layoutBox .contactForm button:hover {
  color: #fff;
  box-shadow: 0 0 20px #d63a94;
}
.contact .bgcBox .layoutBox .contactForm button:focus {
  outline: none;
  box-shadow: 0 0 20px #d63a94;
}
.contact .bgcBox .layoutBox .msg-status {
  justify-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.2em;
  width: 90%;
  height: 4rem;
  border-radius: 0.7em;
  font-size: 1.5rem;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}
.contact .bgcBox .layoutBox .success {
  display: flex;
  background-color: hsl(143, 83%, 55%);
  box-shadow: 0 0 20px hsl(143, 83%, 55%);
}
.contact .bgcBox .layoutBox .error {
  display: flex;
  background-color: hsl(9, 100%, 64%);
  box-shadow: 0 0 20px hsl(9, 100%, 64%);
}

@media (min-width: 768px) {
  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact .bgcBox {
    width: 90%;
    border-radius: 15px;
    min-height: 80vh;
  }
  .contact .bgcBox .headerTxtSection {
    margin: 3em 0 7em 0;
  }
  .contact .bgcBox .headerTxtSection p {
    font-size: 1.8rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt h2 {
    font-size: 3.5rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt p {
    font-size: 1.5rem;
  }
  .contact .bgcBox .layoutBox .contactForm label {
    font-size: 1.5rem;
  }
  .contact .bgcBox .layoutBox .contactForm input,
  .contact .bgcBox .layoutBox .contactForm textarea,
  .contact .bgcBox .layoutBox .contactForm button {
    font-size: 1.5rem;
  }
  .contact .bgcBox .layoutBox .contactForm textarea {
    min-height: 15em;
  }
  .contact .bgcBox .layoutBox .contactForm button {
    width: 15rem;
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .contact .bgcBox {
    width: 90%;
    border-radius: 15px;
    min-height: 80vh;
  }
  .contact .bgcBox .headerTxtSection {
    margin: 3em 0 7em 0;
  }
  .contact .bgcBox .headerTxtSection p {
    font-size: 2.2rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt h2 {
    font-size: 4rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt p {
    padding: 0.5em 3em;
    font-size: 2.2rem;
  }
  .contact .bgcBox .layoutBox .contactForm label {
    font-size: 2.2rem;
  }
  .contact .bgcBox .layoutBox .contactForm input,
  .contact .bgcBox .layoutBox .contactForm textarea {
    font-size: 2.2rem;
  }
  .contact .bgcBox .layoutBox .contactForm textarea {
    min-height: 15em;
  }
  .contact .bgcBox .layoutBox .contactForm button {
    width: 15rem;
    font-size: 1.8rem;
  }
  .contact .bgcBox .layoutBox .msg-status {
    height: 6rem;
    font-size: 2.2rem;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  .contact .bgcBox .headerTxtSection p {
    font-size: 1.7rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt p {
    font-size: 1.8rem;
  }
  .contact .bgcBox .layoutBox .contactForm label {
    font-size: 1.8rem;
  }
  .contact .bgcBox .layoutBox .contactForm input,
  .contact .bgcBox .layoutBox .contactForm textarea {
    font-size: 1.8rem;
  }
  .contact .bgcBox .layoutBox .contactForm textarea {
    min-height: 10em;
  }
  .contact .bgcBox .layoutBox .contactForm button {
    width: 10rem;
    font-size: 1.5rem;
  }
  .contact .bgcBox .layoutBox .msg-status {
    height: 4rem;
    font-size: 1.8rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  .contact {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .contact .bgcBox {
    width: 90%;
    min-height: 80vh;
  }
  .contact .bgcBox .headerTxtSection {
    margin: 3em 0 5em 0;
  }
  .contact .bgcBox .headerTxtSection p {
    font-size: 2rem;
  }
  .contact .bgcBox .layoutBox {
    flex-direction: row;
  }
  .contact .bgcBox .layoutBox .topSectionTxt h2 {
    font-size: 4rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt p {
    font-size: 1.8rem;
    padding: 0.5em 4em;
  }
  .contact .bgcBox .layoutBox .contactForm {
    padding: 0 10em 2em 0;
  }
  .contact .bgcBox .layoutBox .contactForm label {
    font-size: 1.5rem;
  }
  .contact .bgcBox .layoutBox .contactForm input,
  .contact .bgcBox .layoutBox .contactForm textarea {
    font-size: 1.5rem;
  }
  .contact .bgcBox .layoutBox .contactForm textarea {
    min-height: 15em;
  }
  .contact .bgcBox .layoutBox .contactForm button {
    width: 15rem;
    font-size: 1.3rem;
  }
  .contact .bgcBox .layoutBox .msg-status {
    left: 72%;
    width: 45%;
    height: 4rem;
    font-size: 1.5rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  .contact .bgcBox .headerTxtSection p {
    font-size: 2.5rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt h2 {
    font-size: 5.5rem;
  }
  .contact .bgcBox .layoutBox .topSectionTxt p {
    font-size: 2rem;
  }
  .contact .bgcBox .layoutBox .contactForm {
    padding: 0 15em 2em 0;
  }
  .contact .bgcBox .layoutBox .contactForm label {
    font-size: 2rem;
  }
  .contact .bgcBox .layoutBox .contactForm input,
  .contact .bgcBox .layoutBox .contactForm textarea {
    font-size: 2rem;
  }
  .contact .bgcBox .layoutBox .contactForm button {
    font-size: 1.5rem;
  }
  .contact .bgcBox .layoutBox .msg-status {
    left: 71%;
    height: 5rem;
    font-size: 1.8rem;
  }
}
footer {
  width: 100%;
  min-height: 10em;
  padding: 2em 1em;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
footer .left-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
}
footer .left-section img {
  width: 9em;
  padding-bottom: 0.5em;
}
footer .left-section .copyrights {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}
footer .left-section .copyrights span {
  color: #d63a94;
  font-weight: 600;
}
footer .left-section .copyrights .date {
  color: #fff;
  font-weight: 500;
}
footer .left-section .createdby {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 200;
}
footer .left-section .createdby a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
  font-weight: 400;
}
footer .left-section .createdby a:hover,
footer .left-section .createdby a:focus {
  outline: none;
  color: #d63a94;
}
footer .left-section .createdby a:visited {
  color: #fff;
  text-decoration: none;
}
footer .right-section {
  width: 100%;
  padding: 2em 0 0 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: center;
}
footer .right-section h4 {
  margin: 0;
  padding-bottom: 0.5em;
  font-size: 1.3rem;
}
footer .right-section p {
  margin: 0;
  font-size: 1.3rem;
  font-weight: 200;
}
footer .right-section .regulations {
  padding-top: 1em;
  font-size: 1.3rem;
}
footer .right-section .regulations a {
  text-decoration: none;
  color: #fff;
  font-weight: 200;
}
footer .right-section .regulations a:hover {
  font-weight: 400;
}
footer .right-section .regulations a:visited {
  text-decoration: none;
  color: #fff;
}

@media (min-width: 768px) {
  footer {
    justify-content: space-around;
    flex-direction: row;
  }
  footer .left-section {
    width: 50%;
  }
  footer .left-section img {
    width: 12em;
    padding-bottom: 1em;
  }
  footer .right-section {
    width: 50%;
    padding: 2em 0 2em 0;
  }
}
@media (min-width: 1020px) and (orientation: landscape) {
  footer {
    padding: 2em 20em;
  }
  footer .left-section img {
    width: 15em;
  }
  footer .left-section .copyrights {
    font-size: 1.8rem;
  }
  footer .left-section .createdby {
    font-size: 1.4rem;
  }
  footer .right-section h4 {
    font-size: 1.5rem;
  }
  footer .right-section p {
    font-size: 1.5rem;
  }
  footer .right-section .regulations {
    font-size: 1.5rem;
  }
}
@media (min-width: 1800px) and (orientation: landscape) {
  footer {
    padding: 2em 50em;
  }
  footer .left-section img {
    width: 10em;
  }
  footer .left-section .copyrights {
    font-size: 1.5rem;
  }
  footer .left-section .createdby {
    font-size: 1.2rem;
  }
  footer .right-section h4 {
    font-size: 1.5rem;
  }
  footer .right-section p {
    font-size: 1.5rem;
  }
  footer .right-section .regulations {
    font-size: 1.3rem;
  }
}
@media (min-width: 2500px) and (orientation: landscape) {
  footer {
    padding: 2em 80em;
  }
  footer .left-section img {
    width: 13em;
  }
  footer .left-section .copyrights {
    font-size: 1.8rem;
  }
  footer .left-section .createdby {
    font-size: 1.4rem;
  }
  footer .right-section h4 {
    font-size: 1.7rem;
  }
  footer .right-section p {
    font-size: 1.7rem;
  }
  footer .right-section .regulations {
    font-size: 1.4rem;
  }
}